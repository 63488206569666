import { Meta, Title } from '@solidjs/meta';
import { Heading, Container, BreadcrumbItem, Section, Picture, ContentCarousel, Page } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { useLocation } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import type { RouteDefinition } from '@solidjs/router';

export default function TeamTroonPage() {
	const loc = useLocation();

	return (
		<>
			<NoHydration>
				<Title>Team Troon | Troon</Title>
				<Meta
					name="description"
					content="Team Troon is a collection of top international golf talent representing the Troon brand throughout professional golf."
				/>

				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/web/hero/team-troon.jpg`}
					crop="center"
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Team Troon</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1" class="text-center text-white">
						Team Troon
					</Heading>
				</Hero>
			</NoHydration>

			<Page>
				<NoHydration>
					<Container>
						<Section>
							<Container class="flex max-w-6xl flex-col">
								<p>
									Team Troon is a collection of top international golf talent representing the Troon brand throughout
									professional golf. PGA TOUR golfers Justin Thomas, Gary Woodland, and Matt Kuchar of the United
									States, Alex Noren of Sweden, Matthew Fitzpatrick of England, LPGA Tour/Ladies European Tour player
									Cheyenne Woods and Golf Hall of Famer/Champions Tour player Colin Montgomerie make up Team Troon. The
									Troon logo has a presence on each golfer’s golf bag, while Woods and Fitzpatrick will carry full
									Troon-branded golf bags embroidered on the pockets. Additionally, all members of Team Troon
									collaborate on select digital and social campaigns in cooperation with Troon and Troon-managed
									facilities.
								</p>
							</Container>
							<Picture
								src="https://images.ctfassets.net/rdsy7xf5c8dt/6J1ScQd4GBT1R0XpeuFkWj/2e688f01129261d12eddda5d869b7f8e/Team_Troon_On_Tour_Header__1_.png"
								width={1067}
								height={533}
								sizes="(min-width: 1920px) 75vw, 95vw"
								alt="Team Troon"
								class="w-full rounded bg-cover object-contain"
							/>
						</Section>
					</Container>

					<Section appearance="primary">
						<Container size="small">
							<blockquote
								// eslint-disable-next-line tailwindcss/no-custom-classname
								class="quotes flex flex-col items-center gap-8 text-center text-white"
							>
								<q class="text-xl font-semibold md:text-3xl">
									Team Troon is yet another point of differentiation between Troon and the competition. Whether it’s the
									unparalleled results we have at our facilities, our industry-leading social media imprint, the
									fantastic playing surfaces at our clubs, or our strong presence in professional golf, Troon is
									prepared for a strong future and, as always, at the tip of the spear.
								</q>

								<p class="flex flex-col items-center gap-2">
									<Picture
										src="https://images.ctfassets.net/rdsy7xf5c8dt/2bWgca9kBxu9eKVbD9DeCZ/c340d56f1dfb7998321cc3c639d2346f/dana-garmany.jpg"
										width={100}
										height={100}
										sizes="2rem"
										alt="Headshot of Troon Founder Dana Garmany"
										loading="lazy"
										class="size-16 rounded-full"
										crop="face"
									/>
									<span class="text-lg font-semibold">Dana Garmany</span>
									<span>Chairman and Founder, Troon</span>
								</p>
							</blockquote>
						</Container>
					</Section>
				</NoHydration>

				<Container>
					<ContentCarousel containerClass="rounded bg-neutral-100">
						<For each={team}>
							{(player) => (
								<ContentCarousel.Item>
									<div class="flex flex-wrap items-center gap-8 p-8 md:flex-nowrap md:gap-12 md:p-16">
										<Picture
											src={player.img}
											alt=""
											width={480}
											height={640}
											sizes="(min-width: 1024px) 18rem, 9rem"
											loading="lazy"
											class="h-48 w-36 shrink-0 rounded lg:h-96 lg:w-72"
											crop="face"
											mode="crop"
										/>
										<div class="flex shrink flex-col justify-center gap-8">
											<Heading as="h3">{player.name}</Heading>
											<p>{player.content}</p>
										</div>
									</div>
								</ContentCarousel.Item>
							)}
						</For>
					</ContentCarousel>
				</Container>
			</Page>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const team: Array<{ name: string; content: string; img: string }> = [
	{
		name: 'Justin Thomas',
		content:
			'Born in Louisville, KY, Justin Thomas is currently the number eight ranked golfer in the world.Thomas, who attended the University of Alabama, has 12 total PGA TOUR victories including therecent 2020 Sentry Tournament of Champions at Troon-managed Kapalua.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/justin-thomas.jpeg`,
	},
	{
		name: 'Gary Woodland',
		content:
			'Born in Topeka, KS, Gary Woodland is currently the number 96th ranked golfer in the world.Woodland, a Kansas alum, is coming off his most memorable PGA TOUR season of his career which sawhim win his first major, the 2019 U.S. Open.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/gary-woodland.jpeg`,
	},
	{
		name: 'Matt Kuchar',
		content:
			'Born in Winter Park, FL, Matt Kuchar is currently the number 65th ranked golfer in the world.Kuchar, a Georgia Tech alum, has nine PGA TOUR Victories in his career, two of which in 2019winning the Mayakoba Golf Classic, Sony Open in Hawaii.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/matt-kuchar.jpeg`,
	},
	{
		name: 'Alex Noren',
		content:
			'Born in Stockholm, Sweden, Alex Noren is the 40th ranked golfer in the world. Noren, an OklahomaState University alum, has 11 career international tournament victories and represented Team Europein the 2018 Ryder Cup.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/alex-noren.jpeg`,
	},
	{
		name: 'Cheyenne Woods',
		content:
			'Born in Phoenix, AZ, Cheyenne is a professional Golfer on the LPGA &amp; LET Tours. A graduate ofWake Forest, Cheyenne has two professional tournament victories.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/cheyenne-woods.jpeg`,
	},
	{
		name: 'Benedetta Moresco',
		content:
			'Originally from Vicenza, Italy, Moresco turned professional following the European Ladies’ TeamChampionship in July 2023. Recently, she was the low amateur at the U.S. Women’s Open at Pebble Beach Golf Links, finishing T37.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/benedetta-moresco.jpeg`,
	},
	{
		name: 'Erik van Rooyen',
		content:
			'Born in Bellville, South Africa, Erik van Rooyen is the 70th ranked golfer in the world. Van Rooyen is a University of Minnesota alum with two career wins on the PGA TOUR.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/erik-van-rooyen.jpg`,
	},
	{
		name: 'Alex Fitzpatrick',
		content:
			'Born in Sheffield, England, Alex Fitzpatrick is ranked 157th in the world. Fitzpatrick turned pro in 2022 after graduating from Wake Forest University.',
		img: `${getConfigValue('IMAGE_HOST')}/web/about/alex-fitzpatrick.jpg`,
	},
];
